@import "../../../styles/helpers";

.placeTrade {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: $n8;
    overflow-y: auto;
    padding: 12px;

    @include dark {
        background: $n0; } }

.nav {
    display: flex;
    margin-bottom: 12px; }

.exploreLink {
  @include button-1;
  color: $n3;
  cursor: pointer; }

.title {
    @include body-bold-1;
    margin-bottom: 20px; }

.button {
    flex: 1; }

.content {
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
    margin-right: 6px; }

  &::-webkit-scrollbar-thumb {
    background-color: $n2;
    border-radius: 10px; } }

.wrapper {
  display: flex;
  flex-direction: column; }

.btns {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  gap: 12px; }

.headings {
  color: $n8; }

.buttonBuy {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  background: $p1;
  @include button-1;
  text-align: center;
  transition: all .2s;
  background: $n0;
  margin-left: 2px;
  margin-right: -3px;
  flex: 1;
  box-shadow: 0 0 0 2px $n11 inset;
  color: $n6;
  svg {
    fill: $n4; }
  @include dark-common {
    box-shadow: 0 0 0 2px $n4 inset; }
  &:hover,
  &.active {
    z-index: 0;
    background: none;
    color: $p8;
    box-shadow: 0 0 0 2px $p8 inset; } }

.buttonSell {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  @include button-1;
  text-align: center;
  transition: all .2s;
  background: none;
  flex: 1;
  box-shadow: 0 0 0 2px $n11 inset;
  color: $n6;
  svg {
    fill: $n4; }
  @include dark-common {
    box-shadow: 0 0 0 2px $n4 inset; }
  &:hover,
  &.active {
    color: $p3;
    background: none;
    box-shadow: 0 0 0 2px $p3 inset; } }
