@import "../../../styles/helpers";

.balance {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: $n8;
    max-height: 100%;
    overflow-y: auto;

    @include dark {
        background: $n0; } }

.head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-bottom: $n2 solid 1px; }

.nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly; }

.direction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    transition: all .2s;
    span {
        width: 12px;
        height: 2px;
        border-radius: 1;
        &:not(:last-child) {
            margin-bottom: 2px; } }
    &:hover,
    &.active {
        background: #F1F2F4;
        @include dark {
            background: $n2; } }
    &:not(:last-child) {
        margin-right: 12px; } }

.dropdown {
    margin-left: -7px; }

.dropdownHead {
    height: 32px;
    margin-inline-start: 10px;
    padding: 0 36px 0 8px;
    background: #F1F2F4;
    border-radius: 4px;
    box-shadow: none;
    font-size: 12px;
    line-height: 32px; }

.dropdownArrow {
    right: 0;
    svg {
        fill: $n4; } }

.dropdownBody {
    border-radius: 4px; }

.dropdownOption {
    padding: 4px 8px;
    font-size: 12px; }

.top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    padding: 4px 16px;
    @include caption-bold-2;
    color: $n4; }

.item {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 4px 16px;
    @include caption-2;
    font-weight: 500;
    &.negative {
        .line {
            background: rgba($p3, .15);
            border-left: $p3 solid 2px; }
        .price {
            color: $p3; } }
    &.positive {
        .line {
            background: rgba($p8, .15);
            border-left: $p8 solid 2px; }
        .price {
            color: $p8; } }
    &:not(:last-child) {
        margin-bottom: 4px; }
    &:nth-child(n+10) {
        @include d {
            display: none; }
        @include t {
            display: flex; } } }

.price {
    font-weight: 600; }

.price,
.amount {
    flex-shrink: 0;
    width: 80px; }

.amount {
    margin-left: 13px;
    text-align: right; }

.total {
    flex-grow: 1;
    text-align: right; }

.line {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0; }

.statistics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding: 12px 16px;
    @include body-bold-2;
    @include d {
        margin-top: 4px; }
    @include t {
        margin-top: 12px; }
    svg {
        margin-left: 8px; }
    &:not(:last-child) {
        margin-bottom: 12px;
        @include d {
            margin-bottom: 0; }
        @include t {
            margin-bottom: 12px; } }
    &.positive {
        .currency {
            color: $p4; }
        svg {
            fill: $p4; } }
    &.negative {
        .currency {
            color: $p3; }
        svg {
            fill: $p3; } } }

.money {
    margin-left: 8px; }

.percent,
.spread {
    color: $n4; }

.tab {
    display: flex;
    gap: 2px;
    margin-left: auto;
    align-items: center;
    justify-content: center;
    svg {
        color: $n4;
        fill: $n4; } }
