@import "../../../styles/helpers";

.row {
    display: flex;
    background: $n7;
    @include m {
        display: block; }
    @include dark {
        background: $n1; } }

.col {
    &:first-child {
        flex-grow: 1;

        .price {
            color: $n4; } }
    &:nth-child(2) {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 285px;
        padding: 24px;
        background: $n8;
        border-radius: 4px;
        @include m {
            width: 100%;
            margin-top: 4px; }
        @include dark {
            background: $n0; }
        .price {
            margin-bottom: 16px;
            @include body-bold-1; } } }

.list {
    display: flex;
    flex-wrap: wrap; }

.item {
    display: flex;
    align-items: flex-start;
    flex: 1;
    min-width: 30%;
    max-width: 33.333%;
    border-right: $n1 solid 4px;
    border-bottom: $n1 solid 4px;
    padding: 20px 32px;
    background: $n8;

    &:nth-of-type(3n) {
        border-right: none; }
    &:nth-of-type(2n) {
        border-right: $n1 solid 4px; }
    &:nth-of-type(5n) {
        max-width: 66.666%;
        border-right: none; }
    @include t {
        min-width: 45%;
        max-width: 50%;

        &:nth-of-type(3n) {
            border-right: $n1 solid 4px; }
        &:nth-of-type(2n) {
            border-right: none; }
        &:nth-of-type(5n) {
            max-width: unset; } }

    @include x {
        flex: 0 0 calc(100%);
        width: calc(100%); }

    @include m {
        max-width: 100%;
        padding: 20px 24px;
        width: calc(100%);
        flex: 0 0 calc(100%);
        border-right: none;

        &:nth-of-type(2n) {
            border-right: none; }
        &:nth-of-type(3n) {
            border-right: none; } }

    @include dark {
        background: $n0; } }


.title {
    display: flex;
    align-items: center;
    margin-right: auto;
    font-weight: 500;
    color: $n3;
    @include dark {
        color: $n7; } }

.bg {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    margin-top: 4px;
    margin-right: 8px;
    border-radius: 4px; }

.details {
    text-align: right; }

.currency {
    @include body-bold-2; }

.info {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .category {
        border-radius: 13px; } }

.money {
    margin-right: 12px;
    @include caption-bold-2;
    color: $n4; }

.chart {
    width: 100%;
    height: 52px;
    margin-top: auto; }
