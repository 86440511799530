@import "../../../styles/helpers";

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.headerRow {
    display:  table-row;
    flex: 1;
    min-width: 100%;
    width: fit-content;
    margin-bottom: 12px;
    max-height: 32px;

    &:first-child {
        @include t {
            display: none; }
        .col {
            @include caption-bold-2;
            color: $n4;
            @include dark {
                border-color: $n2; }
            svg {
                margin-left: 4px;
                fill: $n4; } } }
    &:not(:first-child) {
        .col {
            @include caption-2;
            padding-top: 13px;
            font-weight: 500; } } }

.row {
    width: 100%;
    border-top: $n2 solid 1px;
    display:  table-row;
    padding: 6px 0;

    &:hover {
        background: $n2;
        @include t {
            color: $n2;
            @include dark {
                border-color: $n1; }
            svg {
                fill: $n2; } } } }

.col {
    display: table-cell;
    padding: 16px;
    width: 200px;
    text-align: right;
    font-weight: 500;
    @include t {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        padding: 0; }
    @include dark {
        color: $n8; }
    &:first-child,
    &:nth-child(2) {
        text-align: left; }
    &:nth-child(2) {
        @include t {
            margin-bottom: 16px; } }
    &:nth-last-child(2) {
        @include d {
            display: none; } }
    &:first-child {
        font-size: 12px;
        @include t {
            display: none; } }
    &:last-child {
        @include t {
            margin-bottom: 0; } } }

.line {
    display: inline-flex;
    align-items: center;
    @include caption-bold-2; }

.item {
    display: flex;
    align-items: center; }

.icon {
    flex-shrink: 0;
    width: 32px;
    margin-right: 12px;
    img {
        max-width: 100%; } }

.subtitle {
    margin-right: 4px; }

.currency {
    font-weight: 400;
    color: $n4; }

.positive {
    color: $p8; }

.negative {
    color: $p3; }

.button {
    display: none;
    width: 96px;
    @include d {
        display: inline-flex; }
    @include t {
        position: absolute;
        top: -6px;
        right: 0; } }

.label {
    display: none;
    @include t {
        display: block;
        margin-right: auto;
        @include caption-bold-2;
        color: $n4; } }
