@import "../../../styles/helpers";

.balance {
    background: $n8;
    @include dark {
        background: $n0; } }

.head {
    display: flex;
    align-items: center;
    padding: 16px 16px 12px; }

.nav {
    display: flex;
    align-items: center;
    margin-right: auto; }

.link {
    display: flex;
    padding: 10px;
    border: 1px solid $n11;
    border-radius: 10px;
    background: none;
    @include button-2;
    color: $n4;
    transition: all .2s;
    &:hover {
        color: $n3;
        @include dark {
            color: $n6; } }
    &.active {
        background: $n6;
        color: $n2;
        @include dark {
            background: $n11;
            color: $n8; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.direction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    transition: all .2s;
    span {
        width: 12px;
        height: 2px;
        border-radius: 1;
        &:not(:last-child) {
            margin-bottom: 2px; } }
    &:hover,
    &.active {
        background: #F1F2F4;
        @include dark {
            background: $n2; } }
    &:not(:last-child) {
        margin-right: 12px; } }

.dropdownHead {
    height: 32px;
    margin-inline-start: 10px;
    padding: 0 36px 0 8px;
    background: #F1F2F4;
    border-radius: 4px;
    box-shadow: none;
    font-size: 12px;
    line-height: 32px; }

.dropdownArrow {
    right: 0;
    svg {
        fill: $n4; } }

.dropdownBody {
    border-radius: 4px; }

.dropdownOption {
    padding: 4px 8px;
    font-size: 12px; }

.bookList {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto; }

.top {
    display: flex;
    justify-content: space-between;
    padding: 4px 16px;
    border-bottom: $n2 solid 1px;

    @include caption-bold-2;
    color: $n4; }

.left {
    display: flex;
    flex-direction: column; }

.item {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 4px 9px 4px 16px;
    @include caption-2;
    font-weight: 500;
    &.negative {
        .line {
            background: rgba($p3, .20);
            border-left: $p3 solid 2px;
            z-index: 5; }
        .price {
            color: $p3; } }
    &.positive {
        .line {
            background: rgba($p8, .15);
            border-left: $p8 solid 2px; }
        .price {
            color: $p8; } }
    margin-top: 2px;
    margin-bottom: 2px; }

.price {
    font-weight: 600; }

.price,
.amount {
    flex-shrink: 0;
    width: 120px; }

.amount {
    margin-left: 13px;
    text-align: right; }

.total {
    flex-grow: 1;
    text-align: right; }

.line {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0; }

.statistics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-top: $n2 solid 1px;
    border-bottom: $n2 solid 1px;
    @include body-bold-2;
    svg {
        margin-left: 8px; }
    &:not(:last-child) {
        @include d {
            margin-bottom: 0; } }
    &.positive {
        .currency {
            color: $p4; }
        svg {
            fill: $p4; } }
    &.negative {
        .currency {
            color: $p3; }
        svg {
            fill: $p3; } } }

.money {
    margin-left: 8px; }

.percent,
.spread {
    color: $n4; }

.tab {
    display: flex;
    gap: 2px;
    margin-left: auto;
    align-items: center;
    justify-content: center;
    svg {
        color: $n4;
        fill: $n4; } }

.book {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 3px;
        margin-right: 6px; }

    &::-webkit-scrollbar-thumb {
        background-color: $n2;
        border-radius: 10px; } }

.bookShort {
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    width: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 3px;
        margin-right: 6px; }

    &::-webkit-scrollbar-thumb {
        background-color: $n2;
        border-radius: 10px; } }
