@import "../../styles/helpers";

.exchange {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 88px);
    max-height: 100%;
    border-top: $n1 solid 2px;
    border-bottom: $n1 solid 2px;
    background: #F1F2F4;

    @include t;
    @include dark {
        background: $n1; } }

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: $n2 solid 1px;

    @include m {
        overflow-y: unset;
        min-height: calc(100vh - 96px);
        max-height: calc(100vh - 96px); } }

.balanceWrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
    @include m {
        overflow-y: hidden; } }

.box {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto; }

.exploreLink {
  @include button-1;
  color: $n3;
  cursor: pointer; }

.nav {
    display: none;
    margin: 0 12px;
    @include t {
        display: flex;
        flex-direction: row;
        margin: 12px; }
    @include m {
        justify-content: space-between; } }

.link {
    display: flex;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $n3;
    transition: all .2s;
    &.active {
        background: $n3;
        color: $n8;
        @include dark {
            background: $n2;
            color: $n8; } }
    &:not(:last-child) {
        margin-right: 8px;
        @include m {
            margin-right: 0; } } }

.row {
    display: flex;
    margin-top: 4px;
    @include d {
        display: block;
        &:after {
            content: " ";
            display: table;
            clear: both;
            @include t {
                display: none; } } } }

.orderbookNew {
    width: 256px; }

.flexContainer {
    display: flex;
    flex-direction: row;
    border-top: $n1 solid 2px;
    overflow: hidden; }

.contentLeft {
    display: flex;
    flex: 1;
    overflow-y: auto; }

.account {
    display: flex;
    flex-direction: column;
    flex: 0 0 350px;
    border-left: $n2 solid 1px; }

.balance {
    display: flex;
    flex-direction: column;
    width: 350px;
    max-height: 100%;
    flex-shrink: 0;
    overflow-y: auto;
    border-right: $n2 solid 1px; }

.chartContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: auto; }

.chartGraphTable {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    flex: 1;
    border-left: $n1 solid 2px; }

.chart {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden; }

.table {
    display: flex;
    height: 300px;
    min-height: 300px;
    max-height: 300px;
    flex-basis: 300px;
    width: 100%; }
