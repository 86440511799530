@import "../../../styles/helpers";

.charts {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
    flex: 1;
    max-height: 100%;
    overflow: hidden;
    background-color: $n0;

    img {
        width: 100%; } }

.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $n8;
    padding: 12px;
    border-bottom: 1px solid $n6;
    gap: 6px;

    @include dark {
        background: $n0;
        border-color: $n2; }
    @include m {
        flex-direction: column-reverse;
        align-items: flex-end; } }

.nav {
    display: flex;
    @include m {
        margin-bottom: 12px;
        width: 100%; } }

.navButton {
    @include m {
        flex: 1; } }

.interval {
    display: none;
    padding: 10px;
    margin-right: 15px;
    background: none;
    @include button-2;
    color: $n4;
    &:hover {
        cursor: text; }
    @include w {
        display: flex; }
    @include m {
        display: none; } }

.group {
    display: flex;
    flex-direction: row;
    align-items: center;
    .nav {
        @include m {
            display: none; }
        @include w {
            display: none; } } }

.dropdown {
    display: none;
    @include m {
        display: block; }
    @include w {
        display: block; } }

.dropdownHead {
    height: 28px;
    line-height: 28px;
    background: $n7;
    @include dark {
        background: $n2; } }

.time {
    padding: 10px;
    margin-right: 15px;
    background: none;
    @include button-2;
    color: $n4;
    &:hover {
        cursor: text; } }

.dates {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    border: 1px solid $n11;
    margin-right: 10px;
    border-radius: 13px;
    background: none;
    @include button-2;
    color: $n4;
    transition: all .2s;
    &:hover {
        color: $n3;
        @include dark {
            color: $n6; } }
    &.active {
        background: $n6;
        color: $n2;
        @include dark {
            background: $n11;
            color: $n8; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.inner {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    background: $n0; }

.iframe {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    @include t {
        position: relative; } }

.chart {
    width: 100%;
    height: 100%;
    background: $n8;
    padding: 2rem;
    @include dark {
        background: $n1; } }
