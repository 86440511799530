@import "../../../styles/helpers";

.account {
    background: $n8;
    border-bottom: $n1 solid 2px;
    @include m {
        flex: 1; }

    @include dark {
        background: $n0; } }

.rowTitle {
    font-size: 14px;
    line-height: 1.66667;
    font-weight: 600;
    color: $n4; }


.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $n2;
    padding: 19px 12px; }

.rowBtns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end; }

.nav {
    display: flex;
    margin-bottom: 12px; }

.title {
    @include body-bold-1;
    margin-bottom: 20px; }

.btns {}

.button {
    height: 28px;
    border-radius: 6px;
    padding: 6px 12px;
    width: unset;
    margin: 0;
    background: $r1;

    &:hover {
        background: $r2; }

    &:not(:last-child) {
        margin-right: 12px; } }

.secondary {
    background: $r2;

    &:hover {
        background: $r3; } }

.table {
    display: table;
    width: 100%;
    padding: 12px 18px;
    @include m {
        flex: 1; } }

.row {
    display: table-row;
    &:first-child {
        .col {
            @include caption-bold-2;
            color: $n4; } }
    &:not(:first-child) {
        .col {
            @include caption-2;
            font-weight: 500; } }
    &:nth-child(n+12) {
        @include d {
            display: none; } } }

.col {
    display: table-cell;
    padding: 2px 4px;
    max-width: 10ch;
    overflow: hidden;
    &:first-child {
        padding-left: 0; }
    &:nth-child(2) {
        text-align: right;
        padding-right: 12px; }
    &:last-child {
        padding-right: 0;
        text-align: right; } }

.currency {
    color: $n8; }

.positive {
    color: $p4; }

.negative {
    color: $p3; }

.title {
    margin-bottom: 32px; }
