@import "../../../../../styles/helpers";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;

    input {
        flex: 1; }

    input::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: $blueGray700;
        border: $blueGray800 solid 1px; } }

.valueRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0;
    border: #2D4659 solid 2px;
    background-color: none;
    padding: 0 12px;
    border-radius: 12px;
    width: 96px;
    height: 38px; }

.value {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $n8;
    flex: 1;
    overflow: hidden;
    @include body-bold-2;
    font-weight: 1000;
    height: 36px;
    background-color: transparent;
    text-align: left;
    @include poppins;
    font-size: 14px;
    @include dark {
        color: $n8; } }

.placeholder {
    font-weight: 500;
    color: $n6;
    height: 24px;
    border-radius: 9px;
    background: $n11;
    padding: 0 12px;
    align-items: center;
    display: flex;
    cursor: text; }

.checkbox {
    display: flex; }

.row {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.info {
    display: flex;
    flex-direction: row;
    align-items: center; }

.label {
    font-weight: 500;
    color: $n6;
    pointer-events: none; }

.tip {
    color: #A2A2A2;
    font-size: 12px;
    font-weight: 200;
    pointer-events: none;
    margin-left: 5px; }

.warning {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px 10px 5px 10px;
    background: #3C2D32;
    border-radius: 12px;
    border-left: thick solid $p3; }

.warningText {
    font-size: 11px;
    font-weight: 500;
    line-height: (20/15);
    color: $p3;
    pointer-events: none; }

.switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 18px;

    input {
        width: 0; } }

.switchLabel {
    margin-right: 6px;
    color: $n5; }

.slider {
    background: linear-gradient(270deg, rgba(0, 255, 157, 0.8) 2.38%, rgba(0, 168, 120, 0.8) 52.22%, rgba(81, 103, 153, 0.4) 99.05%);
    border-radius: 8px;
    box-shadow: rgba($r1, 0.1) 0 0 1px 1px;
    height: 12px;
    flex: 1;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
    margin-left: 12px; }


.checkbox {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 32px;
    border-radius: 30px;
    background-color: $blueGray700;
    -webkit-transition: .4s;
    border: $n11 solid 1px;
    transition: .4s;

    &:before {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 2px;
        bottom: 2px;
        background-color: $n5;
        border-radius: 24px;
        -webkit-transition: .4s;
        transition: .4s; } }

input:checked + .checkbox {
    background-color: $blueGray400;
    border-radius: 24px;

    &:before {
        background-color: $n0; } }


input:checked + .checkbox:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px); }

.presets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1; }

.presetLeverage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 0px 0px 0px 12px;
    border: $n3 solid 2px;
    background-color: $n1;
    padding: 4px 6px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        opacity: 0.5; } }
