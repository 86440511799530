@import "../../styles/helpers";

.nameAndSymbol {
    display: flex;
    align-items: center;
    margin-left: 12px; }

.selection {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
