@import "../../../styles/helpers";

.inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-top: $n1 solid 2px;
    border-radius: 4px;
    background: $n8;
    width: 100%;
    overflow: auto;
    @include dark {
        background: $n0; }

    @include m {
        min-height: 300px; } }

.dropdown {
    display: none;
    @include m {
        display: block;
        width: 100%;
        padding: 12px; } }

.exploreLink {
  @include button-1;
  color: $n3;
  cursor: pointer; }

.nav {
    display: flex;
    padding: 12px;
    border-bottom: 1px solid $n2;

    @include m {
        display: none; } }

.table {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto; }

.tableContent {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }

.tableScroll {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1; }

.content {
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-width: 100%;
    overflow-y: auto;
    flex: 1;
    padding: 12px 6px;
    border-top: $n2 solid 1px; }

.header {
    display: flex;
    width: fit-content;
    min-width: 100%; }

.headerRow {
    display: flex;
    flex: 1;
    min-width: 100%;
    width: fit-content;
    max-height: 32px;
    padding: 3px 6px;
    border-bottom: $blueGray800 solid 1px;

    &:first-child {
        .col {
            @include caption-bold-2;
            color: $n4;
            @include dark {
                border-color: $n2; }
            svg {
                margin-left: 4px;
                fill: $n4; } } }
    &:not(:first-child) {
        .col {
            @include caption-2;
            padding-top: 13px;
            font-weight: 500; } } }

.row {
    display: flex;
    align-items: center;
    min-height: 40px;
    height: 40px;
    margin: 4px;

    &.active {
        margin: 50px 0; }

    @include body-2;
    @include m {
        margin: 6px 0; } }

.inlineRow {
    flex-direction: row;
    display: flex;
    align-items: center; }

.col {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    max-width: 150px;
    width: 150px;
    min-width: 150px;

    &:nth-child(3) {
        @include m {
            padding-right: 0;
            text-align: right; } }
    &:last-child {
        text-align: center;
        padding-right: 0;
        @include m {
            display: none; } } }

.stacked {
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 0; }

.marketPositionText {
    font-size: 15px; }

.normalText {
    font-size: 14px;
    font-weight: 500; }

.faintText {
    font-size: 12px;
    color: $n5; }

.faintLargerText {
    font-size: 15px;
    color: $n5; }

.positive {
    color: $p8; }

.negative {
    color: $p3; }

.callToAction {
  display: flex;
  float: right;
  margin-right: 20px;
  padding: 6px 12px;
  border-radius: 8px;
  transition: all .2s;
  background: $n11;
  color: $n8;
  font-weight: 600;
  line-height: 100%;
  &:hover {
    background: $p9;
    @include dark {
        background: $p9; } } }

.item {
    display: flex;
    align-items: center;
    @include m {
        padding: 6px 0; } }

.icon {
    flex-shrink: 0;
    width: 32px;
    margin-right: 12px;
    img {
        max-width: 100%; } }

.subtitle {
    margin-right: 4px; }

.currency {
    font-weight: 400;
    color: $n4; }

.footerRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: $n2 solid 1px;
    overflow-x: auto;
    min-height: 56px;

    &::-webkit-scrollbar {
        display: none; }

    @include t {
        padding: 12px; } }

.footerCol {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    width: 32px;
    min-height: 32px;
    height: 32px;
    border-radius: 3px;
    margin: 6px;
    cursor: pointer;

    @include t {
        &:hover {
            background-color: $n2; } }

    @include m {
        &:hover {
            background-color: unset; } } }

.footerColSelected {
    background-color: $n2;

    &:hover {
        background-color: $n2; } }
