@import "../../styles/helpers";

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 48px;
  background: rgba($n1,.3);
  opacity: 0;
  animation: showModal .4s forwards;
  overflow: auto;
  z-index: 999;

  @include m {
    padding: 16px 8px; }
  @include dark {
    background: rgba($n1,.75); } }

@keyframes showModal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.outer {
  position: relative;
  width: 100%;
  max-width: 448px;
  margin: auto;
  background: $n8;
  border-radius: 8px;
  box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
  z-index: 2;
  @include dark {
    background: $n0;
    border: 1px solid $blueGray700; }
  @include m {
    box-shadow: none; }
  & > div {
    padding: 32px;
    @include m {
      padding: 32px 16px; } } }

.close {
  position: absolute;
  top: 26px;
  right: 34px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 0;
  @include m {
    top: 28px;
    right: 16px; }
  @include dark {
    border-color: $n3;
    background: $n2;
    svg {
      fill: $n8; } }
  &:hover {
    svg {
      transform: rotate(90deg); } } }
