@import "../../../styles/helpers";

.wrap {
    padding-bottom: 4px; }

.table {
    display: table;
    width: 100%; }

.currency {
    font-weight: 500;
    color: $n6;
    height: 30px;
    border-radius: 10px;
    background: $n11;
    padding: 7px;
    align-items: center;
    display: flex;
    cursor: text; }

.row {
    display: table-row;
    color: $n2;
    transition: background .2s;
    cursor: text;
    &:first-child {
        .col {
            padding-top: 20px;
            border-bottom: 1px solid $n7;
            @include caption-bold-2;
            color: $n3;
            @include dark {
                border-color: $n2;
                color: $n8; } } }
    &:not(:first-child):not(:last-child) {
        .col {
            border-bottom: 1px solid $n6;
            @include dark {
                border-color: $n2; } } } }

.col {
    display: table-cell;
    padding: 16px;
    @include m {
        padding: 16px 8px; }
    @include dark {
        color: $n8; }
    &:first-child {
        padding-left: 32px; }
    &:last-child {
        padding-right: 32px; }
    &:nth-child(3),
    &:nth-child(4),
    &:last-child {
        text-align: right; }
    &:not(:first-child):not(:last-child) {
        @include m {
            display: none; } } }

.currency {
    display: flex;
    @include m {
        position: relative;
        bottom: -3px; } }

.icon {
    flex-shrink: 0;
    width: 32px;
    margin-right: 20px;
    @include m {
        margin-right: 12px; }
    img {
        width: 100%; } }

.info {
    font-weight: 500; }

.text {
    color: $n4; }
