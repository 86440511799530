@import "../../styles/helpers";

.chart {
    display: inline-block;
    width: 96px;
    height: 40px;
    @include d {
        display: none; } }

.panelChart {
    flex-grow: 1;
    width: 100%;
    height: 34px;
    margin-left: 12px;
    @include d {
        display: none; } }
