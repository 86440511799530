@import "../../../styles/helpers";

.trades {
    padding: 0px 16px 0px 16px;
    border-radius: 4px;
    background: $n8;
    overflow: auto;
    max-height: 700px;
    @include dark {
        background: $n0; } }

.nav {
    display: flex;
    margin-bottom: 12px; }

.link {
    display: flex;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $n4;
    transition: all .2s;
    &:hover {
        color: $n3;
        @include dark {
            color: $n6; } }
    &.active {
        background: $n6;
        color: $n2;
        @include dark {
            background: $n3;
            color: $n8; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.table {
    display: table;
    width: 100%; }

.row {
    display: table-row;
    &:first-child {
        .col {
            padding-bottom: 12px;
            @include caption-bold-2;
            color: $n4; } }
    &:not(:first-child) {
        .col {
            @include caption-2;
            font-weight: 500; } } }

.col {
    display: table-cell;
    padding: 2px 4px;
    &:first-child {
        padding-left: 0; }
    &:last-child {
        padding-right: 0;
        text-align: right; } }

.line {
    display: inline-flex;
    align-items: center; }

.favorite {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.info {
    span {
        color: $n4; } }

.positive {
    color: $p8; }

.negative {
    color: $p3; }
