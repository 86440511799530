@import "../../styles/helpers";

.top {
    padding: 18px 24px;
    background: $n8;
    border-radius: 4px;

    @include t {
        border-top: $n1 solid 4px; }

    @include dark {
        background: $n0; }
    &:not(:first-child) {
        margin-top: 5px; } }

.stats {
    background: $n8;
    border-radius: 4px;
    border-top: $n1 solid 4px;
    @include dark {
        background: $n0; } }

.market {
    flex: 1;
    padding: 18px 24px;
    background: $n8;
    border-radius: 4px;
    @include dark {
        background: $n0; } }

.row {
    display: flex;
    justify-content: space-between; }

.title {
    padding: 10px;
    margin-right: auto;
    @include m {
        flex: 0 0 100%;
        margin: 0 0 20px; } }

.wrap {
    display: flex;
    align-items: center;
    @include m {
        flex-wrap: wrap; } }

.form {
    position: relative;
    flex-shrink: 0;
    width: 256px;
    margin-right: 8px;
    @include d {
        width: 220px; }
    @include m {
        width: 100%;
        margin: 0 0 16px; } }

.input {
    width: 100%;
    height: 40px;
    padding: 0 40px 0 14px;
    border-radius: 20px;
    background: none;
    border: 2px solid $n6;
    @include poppins;
    @include caption-2;
    color: $n2;
    transition: border-color .2s;
    @include dark {
        border-color: $n3;
        color: $n8; }
    @include placeholder {
        color: $n4; }
    &:focus {
        border-color: $n4; } }

.line {
    display: inline-flex;
    align-items: center;
    @include caption-bold-2; }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $p1; } } }

.dropdown {
    margin-right: 8px; }

.dropdownHead {
    height: 40px;
    line-height: 40px;
    background: $n6;
    border-radius: 20px;
    @include dark {
        background: $n3; } }

.connectBox {
    display: flex;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    width: 60%; }

.smallBox {
    width: 300px; }

.category {
    margin-left: 8px; }
