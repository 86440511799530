@import "../../styles/helpers";

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $n5; }

.wrap {
    position: relative; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 14px;
    border-radius: 12px;
    border: 2px solid $blueGray700;
    background: $n8;
    @include poppins;
    @include caption-bold-1;
    color: $n2;
    transition: border-color .2s;
    @include dark {
        border-color: $blueGray700;
        background: none;
        color: $n8; }
    @include placeholder {
        color: $n4; }
    &:focus {
        border-color: $n4; } }

.toggle,
.preview {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 48px; }

.toggle {
    svg {
        fill: $n5;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $n4; } }
    &.active {
        svg {
            fill: $n1; } }
    @include dark {
        svg {
            fill: $n4; }
        &:hover {
            svg {
                fill: $n5; } }
        &.active {
            svg {
                fill: $n8; } } } }

.preview {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        fill: $n4; } }

.empty {
    .input {
        height: 26px;
        border: none; } }

.view,
.icon {
    .input {
        padding-right: 48px; } }

.note {
    margin-top: 12px;
    @include caption-3;
    font-weight: 500;
    color: $n4; }
