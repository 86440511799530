@import "../../../styles/helpers";

.balance {
    border-radius: 4px;
    background: $n8;
    @include dark {
        background: $n0; } }

.head {
    display: flex;
    align-items: center;
    padding: 16px 0px 16px 0px; }

.nav {
    display: flex;
    flex-direction: column;
    align-items: center; }

.icon {
    flex-shrink: 0;
    width: 30px;
    img {
        max-width: 100%; } }

.row {
    margin-left: 5px;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 10px 15px;
    width: 100%;
    align-items: center;
    &:hover {
        background: $n0;
        @include dark {
            background: $n2; }
        border-radius: 4px; } }

.coinText {
    padding: 10px 5px 10px 5px;
    align-items: center;
    flex-direction: row;
    display: flex; }

.link {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    background: none;
    @include button-1;
    color: $n7;
    transition: all .2s;
    &.active {
        background: $n6;
        color: $n2;
        @include dark {
            background: $n11;
            color: $n8; } } }

.currency {
    font-weight: 500;
    color: $n6;
    height: 30px;
    border-radius: 10px;
    background: $n11;
    padding: 7px;
    align-items: center;
    display: flex;
    cursor: text; }

.amount {
    display: flex;
    padding: 5px;
    margin-right: 10px;
    border-radius: 10px;
    background: none;
    @include button-2;
    color: $n7;
    transition: all .2s; }
