@import "../helpers";

.hero,
.h1,
.h2,
.h3,
.h4,
.description,
.link {
    @include dm-sans; }

.hero {
    font-size: 96px;
    line-height: 1;
    letter-spacing: -.02em;
    @include x {
        font-size: 80px; }
    @include d {
        font-size: 64px; } }

.h1 {
    font-size: 52px;
    line-height: 1;
    letter-spacing: -.02em;
    @include m {
        font-size: 48px;
        line-height: (56/48); } }

.h2 {
    font-size: 40px;
    line-height: (56/48);
    letter-spacing: -.02em;
    @include m {
        font-size: 40px;
        line-height: (48/40);
        letter-spacing: -.01em; } }

.h3 {
    font-size: 36px;
    line-height: (48/40);
    letter-spacing: -.01em;
    @include m {
        font-size: 32px;
        line-height: (40/32); } }

.h4 {
    font-size: 25px;
    line-height: (40/32);
    letter-spacing: -.01em; }

.description {
    padding: 8px 8px 8px 0px;
    margin-left: 6px;
    font-size: 14px;
    font-weight: 100;
    transition: color .2s;
    color: $blueGray400;
    -webkit-tap-highlight-color: rgba(0,0,0,0); }

.link {
    padding: 8px 0px 8px 0px;
    font-size: 14px;
    font-weight: 500;
    transition: color .2s;
    cursor: pointer;
    color: $n3;
    -webkit-tap-highlight-color: rgba(0,0,0,0); }
