@import "../../styles/helpers";

.header {
    position: relative;
    z-index: 10;
    padding: 20px 0;
    box-shadow: inset 0 -1px 0 $n6;
    @include m {
        padding: 32px 0 24px;
        box-shadow: none; }
    @include dark {
        box-shadow: inset 0 -1px 0 $n2;
        @include m {
            box-shadow: none; }
        background: $n0; }
    &.wide {
        box-shadow: none;
        .container {
            max-width: 100%;
            padding: 0 20px;
            @include m {
                padding: 0 24px; } } } }

.container {
    display: flex;
    align-items: center;
    @include d {
        padding: 0 40px; }
    @include t {
        padding: 0 20px; }
    @include m {
        padding: 0 24px; } }

.logo {
    cursor: pointer;
    margin-right: 32px;
    @include t {
        margin-right: 16px; }
    @include m {
        position: relative;
        z-index: 15;
        margin-right: auto; }
    img {
        width: 136px;
        @include t {
            width: 42px; } } }

.picDesktop {
    @include t {
        display: none; } }

.picMobile {
    display: none;
    @include t {
        display: inline-block; } }

.wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 32px;
    border-left: 1px solid $n6;
    @include x {
        padding-left: 0;
        border: none; }
    @include m {
        flex-grow: 0; }
    @include dark {
        border-color: $n3; }
    .theme {
        @include d {
            display: none; } }
    & > .btns {
        @include m {
            display: none; } } }

.wrap {
    margin-right: auto;
    @include m {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        padding: 100px 0 32px;
        background: $n8;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        @include dark {
            background: $n1; }
        &.visible {
            visibility: visible;
            opacity: 1; } }
    & > .button {
        display: none;
        @include m {
            display: flex;
            margin: auto 24px 0; } }
    .btns {
        display: none;
        @include m {
            display: flex; } } }

.nav {
    display: flex;
    @include m {
        flex-direction: column;
        padding: 0 12px; } }

.item,
.nav > div {
    &:not(:last-child) {
        margin-right: 30px;
        @include d {
            margin-right: 32px; }
        @include t {
            margin-right: 20px; }
        @include m {
            margin: 0; } } }

.item {
    @include dm-sans;
    font-size: 14px;
    line-height: 40px;
    font-weight: 700;
    color: $n4;
    @include m {
        padding: 0 22px;
        border-left: 2px solid transparent;
        @include poppins;
        font-size: 24px;
        line-height: 64px;
        font-weight: 600; }
    &:hover {
        color: $n2;
        @include dark {
            color: $n8; }
        @include m {
            border-color: $p1; } }
    &.active {
        color: $n2;
        padding: 0px 10px;
        background: $n11;
        border-radius: 8px;
        @include dark {
            color: $n8; }
        @include m {
            border-color: $p1; } } }

.icon {
    display: flex;
    margin-right: 12px;
    color: white;
    width: 24px;
    padding: 2px;
    min-width: 24px; }

.chevron {
    margin-left: 12px;
    margin-right: 0 !important; }

.control {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 140px;

    .button {
        background: $r1;
        border: none;
        box-shadow: none;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:hover {
            opacity: 0.8;
            background: $r1;
            border: none;
            box-shadow: none; } } }

.settings {
    margin-right: 32px;
    @include d {
        margin-right: 16px; }
    @include t {
        margin-right: 4px; } }


.activity {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    @include m {
        margin-right: 4px; }
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover,
    &.active {
        svg {
            fill: $n2;
            @include dark {
                fill: $n8; } } } }

.btns {
    display: flex;
    @include m {
        margin: auto 16px 0; }
    .button {
        @include m {
            flex: 0 0 calc(50% - 16px);
            width: calc(50% - 16px);
            margin: 0 8px; }
        &:not(:last-child) {
            margin-right: 8px;
            @include m {
                margin-right: 8px; } } } }

.burger {
    display: none;
    @include m {
        display: block;
        position: relative;
        z-index: 15;
        margin-left: 12px;
        width: 32px;
        height: 32px;
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        &.active {
            &:before {
                transform: translateY(0) rotate(-45deg); }
            &:after {
                transform: translateY(0) rotate(45deg); } }
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 16px;
            left: 6px;
            width: 20px;
            height: 2px;
            background: $n4;
            border-radius: 2px;
            transition: transform .2s; }
        &:before {
            transform: translateY(-4px); }
        &:after {
            transform: translateY(3px); } } }

.menu {
    position: absolute;
    top: calc(100% + 12px);
    right: 0;
    background-color: $r2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 6px; }

.menuButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 12px;
    color: #B1B5C3;
    width: 100%;
    border-radius: 3px;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 3px;

    &:hover {
        background-color: #f1f1f122; }

    &:last-of-type {
        margin-bottom: 0; } }

.modal {
    display: flex;
    position: relative;
    border: 1px solid $n6;
    top: 0;
    left: 0;
    width: 100%;
    background: $n0;
    animation: showModal .4s forwards;
    overflow: auto;
    z-index: 999;
    margin: unset;
    @include m {
        padding: 16px 8px; }
    @include dark {
        background: $n0; } }

.dropdown {}
.visible {}
