@import "../../../styles/helpers";

.container {
    display: flex;
    border: 1px solid $n11;
    padding: 24px;
    border-radius: 8px;
    background: $n8;
    @include dark {
        background: $n0; }
    width: 100%;
    height: 100%;
    max-width: 510px; }

.smallerContainer {
    overflow: hidden;
    display: flex;
    border: 1px solid $n11;
    padding: 24px;
    border-radius: 8px;
    background: $n8;
    @include dark {
        background: $n0; }
    width: 100%;
    height: 100%; }

.exploreLink {
    margin: 12px 12px 12px 12px;
    @include button-1;
    cursor: pointer;
    color: $n0;
    &:hover {
        color: $n8; } }

.loading {
    display: flex;
    justify-content: center; }

.clickableIcon {
    margin-left: 5px;
    margin-bottom: -5px;
    margin-top: -3px;
    cursor: pointer;
    &:hover {
        svg {
            fill: $n7; } } }

.input {
    @include body-bold-2;
    font-weight: 1000;
    flex-grow: 1;
    height: 38px;
    padding: 0 10px;
    width: 90%;
    background: none;
    text-align: left;
    @include poppins;
    font-size: 14px;
    @include dark {
        color: $n8; } }

.inputBox {
    display: flex;
    color: $n7;
    background: #0E202D;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    padding: 5px 16px;
    border-radius: 12px;
    box-shadow: inset 0 0 0 1.5px #2D4659;
    cursor: pointer;
    &:not(:last-child) {
        margin-bottom: 12px; } }

.icon {
    display: flex;
    margin-top: -32px;
    border: 2px solid $n11;
    border-radius: 30px;
    width: 50px;
    padding: 10px 0px 0px 10px;
    justify-content: center;
    align-content: center;
    fill: $n4;
    background: $n0;
    &:hover {
        fill: $n8;
        border: 2px solid $blueGray400; } }

.title {
    margin-bottom: 32px;
    padding-right: 56px;
    @include m {
        @include poppins;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600; } }

.link {
    padding: 0px 0px 8px 4px;
    font-size: 14px;
    font-weight: 500;
    transition: color .2s;
    cursor: pointer;
    color: $n3;
    -webkit-tap-highlight-color: rgba(0,0,0,0); }

.back {
    position: relative;
    margin-right: 10px;
    svg {
        transition: fill .2s;
        @include dark {
            fill: $n4; } }
    &:hover {
        svg {
            fill: $n8; } } }

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 100%; }

.field {
    gap: 10px;
    width: 100%;
    margin-top: 10px;
    &:not(:last-child) {
        margin-bottom: 10px; } }

.box {
    margin-top: 25px;
    &:not(:last-child) {
        margin-bottom: 32px; } }

.checkbox {
    margin-top: 25px;
    &:not(:last-child) {
        margin-bottom: 32px; } }

.autofill {
    margin-top: -5px;
    font-size: 14px;
    display: flex;
    color: $n8;
    position: relative;
    float: right;
    padding: 3px 6px;
    border-radius: 3px;
    font-weight: 500;
    background: $n3;

    &:hover {
        color: $n8; } }

.cardTitle {
    margin-top: -5px;
    font-size: 16px;
    display: flex;
    color: #737373;
    font-weight: 500; }

.card {
    padding: 18px 24px 24px 24px;
    margin: 30px 0 20px 0;
    border-radius: 12px;
    background: $n9;
    border: 1px solid $n11; }

.confirmationCard {
    padding: 18px 24px 18px 24px;
    margin: 10px 0 20px 0;
    border-radius: 12px;
    background: $n9; }

.currencyBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 18px;
    margin: 10px 0 20px 0;
    border-radius: 12px;
    background: $n0;
    border: 1px solid $blueGray700;
    width: 100%;
    max-width: 450px;
    overflow-wrap: break-word; }

.rowHeading {
    display: flex;
    align-items: center;
    margin-left: -10px; }

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px; }

.column {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    justify-content: flex-start; }

.rowText {
    display: flex;
    justify-content: space-between; }

.rowKey {
    font-size: 14px;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    color: $n7;
    font-weight: 400; }

.rowValue {
    font-size: 15px;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    color: $n8;
    font-weight: 500; }

.address {
    @include hairline-2;
    color: $n8; }

.connect {
    cursor: pointer;
    color: white;
    background: $n3;
    border: $n11 solid 1px;
    padding: 3px 12px;
    border-radius: 12px;
    margin-left: 12px;
    font-weight: 500;
    @include dm-sans; }

.blankFullWidthBox {
    width: 100%; }

.details {
    text-align: right; }

.price {
    color: $n4; }

.box {
    position: relative;
    width: 100%;
    .button {
        position: absolute;
        top: 36px;
        right: 16px;
        height: 24px;
        padding: 0 16px;
        border-radius: 12px; } }

.withdraw > .button {
    width: 100%;
    margin-top: 10px;
    background: $r1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    border-radius: 17px;
    @include button-1;
    text-align: center;
    color: $n8;
    transition: all .2s;
    &:disabled,
    &.disabled {
        opacity: .5;
        pointer-events: none; } }

.fromDropdown {
    width: 240px; }

.tokenDropdown {
    width: 150px; }

.status {
    font-weight: 500;
    margin: 12px; }

.emoji {
    margin-right: 12px; }

.errorBox {
    max-width: 100%; }

.metamask {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px; }

.mmBalance {
    color: $n8; }

.label {
    @include hairline-2;
    color: $n5; }
