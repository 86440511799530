@import "../../../styles/helpers";

.trades {
    background: $n8;
    max-height: 100%;
    @include dark {
        background: $n0; } }

.table {
    display: table;
    width: 100%;
    max-height: 100%; }

.row {
    display: table-row;
    max-height: 100%;
    &:first-child {
        .col {
            padding-bottom: 12px;
            @include caption-bold-2;
            color: $n4; } }
    &:not(:first-child) {
        .col {
            @include caption-2;
            font-weight: 500; } }
    &:nth-child(n+12) {
        @include d {
            display: none; } } }

.col {
    display: table-cell;
    padding: 2px 4px;
    &:first-child {
        padding-left: 0; }
    &:nth-child(2) {
        text-align: right;
        padding-right: 12px; }
    &:last-child {
        padding-right: 0;
        text-align: right; } }

.loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1; }
