@import "../../styles/helpers";

.top {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background: $n8;
    border-radius: 4px;
    flex: 1;
    height: 100%;
    @include m {
        flex: 1;
        padding: 0; }
    @include dark {
        background: $n0; } }

.header {
    padding: 18px 24px;

    @include m {
        display: none; } }

.line {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }

.title {
    margin-right: auto;
    @include m {
        @include poppins;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600; }
    span {
        margin-left: 12px;
        color: $n5; } }

.back {
    position: relative;
    top: -2px;
    margin-right: 12px;
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $n2; } } }

.btns {
    display: flex;
    @include m {
        display: none; }
    .button {
        &:not(:last-child) {
            margin-right: 12px; } } }

.info {
    margin-bottom: 4px;
    font-weight: 500; }

.currency {
    display: flex;
    align-items: center; }

.number {
    @include body-bold-1; }

.category {
    margin-left: 8px; }

.price {
    @include body-2;
    color: $n4; }

.head {
    padding: 20px 32px 8px;
    @include caption-2;
    font-weight: 500;
    color: $n4;
    @include m {
        padding: 20px 0 8px; } }

.body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1; }

.table {
    padding: 0 12px; }
