@import "../../styles/helpers";

.button {
    background: $r1;
    width: 100%;
    margin-top: 24px;
    &:not(:last-child) {
        margin-right: 16px; } }
.title {
    margin-bottom: 25px; }

.field {
    margin-bottom: 16px;
    margin-top: 25px; }

.exploreLink {
  @include button-1;
  color: $n3; }

.row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 30px; }

.withdraw > .button {
    width: 100%;
    margin-top: 10px;
    background: $n3;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    border-radius: 17px;
    @include button-1;
    text-align: center;
    color: $n8;
    transition: all .2s;
    &:disabled,
    &.disabled {
        opacity: .5;
        pointer-events: none; } }

.bottom {
    padding: 12px; }

.summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%; }

.label {
    font-weight: 400;
    color: $n4; }

.value {
    font-weight: 400; }
