@import "../../../styles/helpers";

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px; }

.counter {
    @include caption-bold-2;
    svg {
        margin-right: 4px; } }

.field {
    display: flex;
    color: $n7;
    background: none;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    padding: 5px 16px;
    border-radius: 12px;
    box-shadow: inset 0 0 0 2px #2D4659;
    cursor: pointer;
    &:not(:last-child) {
        margin-bottom: 12px; } }

.disabled {
    @extend .field;
    background: $n11; }

.input {
    @include body-bold-2;
    font-weight: 1000;
    flex-grow: 1;
    height: 48px;
    padding: 0 10px;
    width: 90%;
    background: none;
    text-align: left;
    @include poppins;
    font-size: 14px;
    color: $n8; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.label {
    font-weight: 500;
    color: $n6;
    pointer-events: none; }

.tip {
    color: #A2A2A2;
    font-size: 12px;
    font-weight: 200;
    pointer-events: none;
    margin-left: 5px; }

.row {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    align-items: center; }

.currency {
    font-weight: 500;
    color: $n6;
    height: 30px;
    border-radius: 10px;
    background: $n11;
    padding: 7px;
    align-items: center;
    display: flex;
    cursor: text;
    gap: 3px; }

.amount {
    font-weight: 300;
    font-size: 10px;
    margin-left: 10px;
    color: $n6;
    height: 30px;
    border-radius: 20px;
    background: $n11;
    padding: 7px;
    align-items: center;
    display: flex;
    cursor: text;
    float: right; }

.button {
    width: 100%; }

.error {
    color: $p3;
    font-size: .65rem;
    line-height: 0; }
