@import "../../../styles/helpers";

.info {
    margin-bottom: 4px;
    font-weight: 500; }

.balance {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 10px; }

.number {
    @include body-bold-1; }

.currency {
    font-weight: 500;
    color: $n6;
    height: 30px;
    border-radius: 10px;
    background: $n11;
    padding: 7px;
    align-items: center;
    display: flex;
    cursor: text;
    margin-left: 7px; }

.price {
    @include body-2;
    color: $n4; }

.line {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @include m {
        flex-wrap: wrap; } }
