@import "../../../styles/helpers";

.main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: $n8;

    @include t {
        display: flex;
        margin-bottom: 16px;
        padding: 16px; }
    @include m {
        flex-direction: column;
        padding: 0;
        margin-bottom: 0; }
    @include dark {
        background: $n0; } }

.details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    @include m {
        display: flex;
        width: 100%; } }

.row {
    display: flex;
    gap: 10px;
    align-items: center; }

.column {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    padding: 6px 12px; }

.marketBox {
    display: flex;
    flex-direction: column;
    margin-right: 24px;

    @include m {
        flex: 1;
        margin-right: 12px; }

    .content {
        display: flex;
        text-align: start;
        white-space: nowrap;

        @include caption-bold-2;
        color: $n4;
        svg {
            margin-right: 4px;
            fill: $n4; } } }

.box {
    display: flex;
    flex-direction: column;
    margin-right: 12px;

    @include m {
        margin-right: 0;
        flex: 1;
        align-items: center; }

    .content {
        display: flex;
        text-align: start;
        white-space: nowrap;

        @include caption-bold-2;
        color: $n4;
        svg {
            margin-right: 4px;
            fill: $n4; } }
    &:not(:last-child) {
        margin-right: 32px;
        @include m {
            margin: 0 0 8px; } } }

.line {
    display: flex;
    align-items: center; }

.info {
    display: flex;
    background: none;
    color: $n4;
    transition: all .2s;
    font-size: 20px;
    line-height: (32/20);
    font-weight: 600;
    letter-spacing: -.01em;
    color: $n1;
    @include dark {
        color: $n8; } }

.category {
    margin-left: 4px;
    padding: 7px 8px 5px;
    border-radius: 12px; }

.price {
    @include body-bold-1;
    color: $p3; }

.list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: auto;

    @include t {
        margin: 0; }
    @include m {
        flex-wrap: wrap;
        width: 100%; } }

.dropdown {
    border: 1px solid $n11;
    margin-left: -2px;
    border-radius: 10px;
    display: flex;
    position: relative;
    &.active {
        background: lighten($n0, 2);
        .arrow {
            svg {
                transform: rotate(180deg); } } } }

.arrow {
    position: absolute;
    top: 50%;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transform: translateY(-50%);
    svg {
        fill: $n2;
        transition: transform .2s; }
    @include dark {
        svg {
            fill: $n4; } } }

.item {
    display: flex;
    flex-direction: column;
    padding-right: 24px;
    margin-right: 18px;

    @include d {
        max-width: 160px;
        &:first-of-type {
            margin-left: auto; } }
    @include t {
        max-width: 160px; }
    @include m {
        flex: 50%;
        padding: 6px 0;
        max-width: 50%;
        align-items: center;
        justify-content: center;
        margin: 0; }


    .content {
        font-weight: 500;
        color: $n1;
        @include dark {
            color: $n8; } }
    &:not(:last-child) {
        border-right: 1px solid $n6;
        @include dark {
            border-color: $n2; } } }

.subtitle {
    display: flex;
    align-items: center;
    margin-block-end: 4px;
    white-space: nowrap;

    @include caption-2;
    color: $n4;
    svg {
        margin-right: 4px;
        fill: $n4; } }
