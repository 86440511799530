@import "../../styles/helpers";

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 18px; }

.icon {
  width: 30px;
  height: 30px;
  color: $n4; }

.text {
  font-size: 30px;
  color: $n4; }

.smallIcon {
  width: 24px;
  height: 24px; }

.smallText {
  font-size: 24px; }
