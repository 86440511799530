@import "../../styles/helpers";

body {
    ::-webkit-scrollbar-thumb {
        border-color: $n2 !important;
        color: $n2 !important;
        background-color: $n2 !important;
        border-radius: 10px; }
    ::-webkit-scrollbar {
        background-color: transparent !important; }
    ::-webkit-scrollbar-track {
        background-color: transparent !important; }
    ::-webkit-scrollbar-corner {
        display: none !important; }
    ::-webkit-resizer {
        display: none !important; }
    ::-webkit-scrollbar-button {
        display: none !important; } }
.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden; }

.inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;

    @include m {
        &::-webkit-scrollbar {
            display: none; } } }
