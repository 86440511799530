@import "../../styles/helpers";

.connectInnerBox {
    background: $n11;
    text-align: center;
    margin: 10px;
    padding: 20px;
    border-radius: 12px; }

.connectWalletButton {
    width: 100%;
    background: $n3;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 20px 24px;
    border-radius: 17px;
    @include button-1;
    text-align: center;
    color: $n8;
    transition: all .2s;
    &:disabled,
    &.disabled {
        opacity: .5;
        pointer-events: none; } }

.message {
    position: relative;
    text-align: center;
    padding-bottom: 10px;
    font-weight: 500;
    color: $n3;
    @include dark {
        color: $n6; } }
