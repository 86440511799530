@import "../../styles/helpers";

.title {
    padding-left: 16px;
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 22px;
    @include m {
        @include poppins;
        font-size: 22px;
        line-height: 40px;
        font-weight: 300; } }

.field,
.wrap,
.box,
.sign {
    &:not(:last-child) {
        margin-bottom: 32px; } }

.featureTitle {
    padding: 0px 16px;
    font-size: 16px;
    font-weight: 300;
    transition: color .2s;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0); }

.subtext {
    padding: 8px 0px 0px 0px;
    margin-left: 6px;
    font-size: 14px;
    font-weight: 100;
    transition: color .2s;
    color: $blueGray400;
    -webkit-tap-highlight-color: rgba(0,0,0,0); }

.link {
    padding: 8px 0px 8px 4px;
    font-size: 14px;
    font-weight: 500;
    transition: color .2s;
    cursor: pointer;
    color: $n3;
    -webkit-tap-highlight-color: rgba(0,0,0,0); }

.category {
    margin-right: auto;
    font-weight: 500;
    color: $n3;
    @include dark {
        color: $n6; } }

.details {
    text-align: right; }

.currency {
    @include body-bold-2; }

.card {
    padding: 18px 24px 24px 24px;
    margin-top: 8px;
    border-radius: 12px;
    background: $blueGray800; }

.row {
    display: flex;
    padding: 15px; }

.subtextRow {
    display: flex;
    margin-top: 24px; }

.walletRow {
    display: flex;
    padding: 15px 30px 15px 22px;
    background: $blueGray700;
    margin: 10px;
    border-radius: 8px;
    justify-content: space-between;
    cursor: pointer; }

.col {
    display: flex;
    flex-direction: column; }

.price {
    color: $n4; }

.sign {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: $blueGray700; }

.continue {
    width: 100%;
    background: $n3;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    border-radius: 17px;
    @include button-1;
    text-align: center;
    color: $n8;
    transition: all .2s;
    &:disabled,
    &.disabled {
        opacity: .5;
        pointer-events: none; } }

.checkbox {
    margin-bottom: 12px;
    padding: 0px 10px 15px 16px; }
