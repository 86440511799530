@import "../../../styles/helpers";

.box {
    display: flex;
    margin-top: 10px;
    // padding: 12px 20px 12px 20px
    // background: $n9
    // border-radius: 12px
    flex-direction: column; }

.table {
    display: table;
    width: 95%; }

.tableRow {
    justify-content: space-around;
    display: table-row;
    &:first-child {
        .col {
            @include caption-bold-2; } }
    &:not(:first-child) {
        .col {
            @include caption-2;
            font-weight: 500; } }
    &:nth-child(n+12) {
        @include d {
            display: none; } } }

.value {
    color: $n8;
    font-size: 12px; }

.col {
    display: table-cell;
    padding: 2px 4px;
    &:first-child {
        padding-left: 0; }
    &:nth-child(2) {
        text-align: right;
        padding-right: 12px; }
    &:last-child {
        padding-right: 0;
        text-align: right; } }

.tip {
    font-size: 12px;
    font-weight: 300;
    pointer-events: none; }

.row {
    display: flex;
    margin-bottom: 10px;
    align-items: center; }
