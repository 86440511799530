[class^="button"] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  background: $p1;
  border-radius: 17px;
  @include button-1;
  text-align: center;
  color: $n8;
  transition: all .2s;
  &:hover {
    background: darken($p1, 15); }
  &:disabled,
  &.disabled {
    opacity: .5;
    pointer-events: none; }
  svg {
    fill: $n8;
    transition: all .2s; }
  &:not([class^="button-circle"]) {
    svg {
      &:first-child {
        margin-right: 12px; }
      &:last-child {
        margin-left: 12px; } } } }

.button-stroke {
  background: $n11;
  box-shadow: 0 0 0 2px $n6 inset;
  color: $n2;
  svg {
    fill: $n4; }
  @include dark-common {
    box-shadow: 0 0 0 2px $n3 inset;
    color: $n8;
    svg {
      fill: $n8; } }
  &:hover,
  &.active {
    background: $n2;
    box-shadow: 0 0 0 2px $n2 inset;
    color: $n8;
    svg {
      fill: $n8; }
    @include dark-common {
      background: $n3;
      box-shadow: 0 0 0 2px $n3 inset; } } }

.currency {
  font-weight: 550;
  color: $n6;
  border-radius: 4px;
  //background: $n11
  padding: 6px 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: text;
  margin-left: 7px; }

.long {
  font-weight: 550;
  color: $p8;
  border-radius: 3px;
  background: #22423D;
  padding: 6px 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: text; }

.short {
  font-weight: 550;
  color: $p3;
  border-radius: 3px;
  background: #30201F;
  padding: 6px 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: text; }

.link {
  display: flex;
  padding: 6px 12px;
  border-radius: 14px;
  background: none;
  @include button-2;
  color: $n4;
  transition: all .2s;
  &:hover {
    color: $n3;
    @include dark {
        color: $n6; } }
  &.active {
    background: $n6;
    color: $n2;
    @include dark {
        background: $n3;
        color: $n8; } }
  &:not(:last-child) {
    margin-right: 8px; } }

.button-primary {
  height: 30px;
  border-radius: 10px;
  background: $r1;
  padding: 20px;
  width: 47%;
  &:hover {
    background: darken($r1, 5); } }

.button-secondary {
  height: 30px;
  border-radius: 10px;
  background: $n11;
  padding: 20px;
  width: 47%;
  &:hover {
    background: darken($n11, 5); } }

.button-black {
  background: $n1;
  &:hover,
  &.active {
    background: $n2; }
  @include dark-common {
    background: $n7;
    color: $n2;
    svg {
      fill: $n2; }
    &:hover,
    &.active {
      background: $n6; } } }

.button-red {
  background: $p3;
  &:hover,
  &.active {
    background: darken($p3, 8); } }

.button-green {
  margin-top: 10px;
  box-shadow: 0 0 0 2px $p8 inset;
  background: #54B283;
  border-radius: 10px;
  &:hover,
  &.active {
    background: darken(#54B283, 12); } }

.button-buy {
  background: $n11;
  width: 45%;
  box-shadow: 0 0 0 2px $n4 inset;
  color: $n6;
  svg {
    fill: $n4; }
  @include dark-common {
    box-shadow: 0 0 0 2px $n4 inset; }
  margin-top: 10px;
  border-radius: 10px;
  &:hover,
  &.active {
    background: darken($p8, 8);
    box-shadow: 0 0 0 2px $p4 inset; } }

.button-sell {
  background: $n11;
  width: 45%;
  box-shadow: 0 0 0 2px $n4 inset;
  color: $n6;
  svg {
    fill: $n4; }
  @include dark-common {
    box-shadow: 0 0 0 2px $n4 inset; }
  margin-top: 10px;
  border-radius: 10px;
  &:hover,
  &.active {
    background: darken($p3, 8);
    box-shadow: 0 0 0 2px $p3 inset; } }

.button-white {
  background: $n8;
  color: $n2;
  svg {
    fill: $n2; }
  &:hover {
    background: $n7; } }

.button-small {
  height: 40px;
  border-radius: 12px;
  padding: 0 16px;
  font-size: 14px; }

.button-logout {
  height: 40px;
  border-radius: 12px;
  padding: 0 16px;
  font-size: 14px;
  background: #223E50;
  color: #FCFCFD; }

.button-icon {
  height: 40px;
  width: 40px;
  border-radius: 12px;
  padding: 0px;
  font-size: 14px;
  margin-right: 8px;
  background: #223E50;
  color: #FCFCFD; }

.button-circle {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 70%; }

.button-circle-stroke {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 70%;
  box-shadow: 0 0 0 2px $n6 inset;
  background: transparent;
  transition: all .2s;
  svg {
      fill: $n4; }
  &:hover {
      background: $n2;
      box-shadow: 0 0 0 2px $n2 inset;
      svg {
          fill: $n8; } }
  @include dark-common {
    box-shadow: 0 0 0 2px $n3 inset;
    &:hover {
      background: $n3; } } }

.button-circle-stroke.button-small {
  flex: 0 0 40px;
  width: 40px;
  height: 40px; }

.tooltip {
  position: relative;
  display: inline-block; }

.tooltip-text {
  z-index: 3;
  visibility: hidden;
  width: 120px;
  background-color: #223E50;
  padding: 12px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 3;
  top: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s; }

.tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #223E50 transparent; }

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1; }

.tabSelect {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid $n11;
  //border-radius: 10px
  background: none;

  @include button-2;
  color: $n4;
  transition: all .2s;
  &:hover {
    color: $n7;
    @include dark {
        color: $n6; } }
  &.active {
    background: $n11;
    color: $n8; }
  //&:not(:last-child)
 }  //  margin-right: 10px

.thirds {
  width: 31%; }
