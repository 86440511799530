@import "../../../styles/helpers";

.chartContainer {
    position: relative;
    width: 100%;
    height: 100%; }

.tooltip {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-left: 2px solid #fff;
    background: '#08161D'; }

.tooltipRow {
    display: flex;
    flex: 1 1 0%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    &:last-of-type {
        margin-bottom: 0; } }

.tooltipLabel {
    margin-right: 16px;
    color: $n4; }

.tooltipValue {
    color: #fff; }
