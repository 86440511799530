@import "../../styles/helpers";

.wallet {
    display: flex;
    flex: 1;
    overflow-y: auto;
    background: $n7;
    height: 100%;
    border-top: $n1 solid 4px;
    border-bottom: $n1 solid 4px;
    padding: 0;

    @include t {
        flex-direction: column;
        padding: 0;
        border-top: none;
        border-bottom: none; }
    @include dark {
        background: $n1; } }

.sidebar {
    display: flex;
    flex-direction: column;
    background: $n8;
    border-radius: 4px;
    flex: 0;
    width: 100%;
    min-width: 256px;
    border-left: $n1 solid 4px;
    border-top: none;
    border-bottom: none;

    @include dark {
        background: $n0; }

    @include t {
        border-left: none;
        flex: 0 0 fit-content;
        background-color: $n1;
        border-radius: 0;
        padding: 12px;
        border-top: $blueGray800 solid 2px;
        border-bottom: $blueGray800 solid 2px; } }


.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    border-left: $n1 solid 4px;
    border-right: $n1 solid 4px;
    @include t {
        border-right: none;
        border-left: none;
        &::-webkit-scrollbar {
            display: none;
            border-radius: 10px; } } }

.group {
    display: flex;
    margin-bottom: auto;

    @include t {
        position: relative;
        z-index: 5;
        &.active {
            .top {
                box-shadow: inset 0 0 0 2px $p1;
                &:after {
                    transform: translateY(-50%) rotate(180deg); } }
            .menu {
                visibility: visible;
                opacity: 1; } } }

    @include m {
        margin-top: 0; } }
.top {
    display: none;
    @include t {
        position: relative;
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0 64px 0 0;
        border-radius: 12px;
        background: $n8;
        box-shadow: inset 0 0 0 2px $n6;
        font-weight: 500;
        transition: all .2s;
        @include dark {
            background: $n0;
            box-shadow: inset 0 0 0 2px $n3; }
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 16px;
            width: 24px;
            height: 24px;
            transform: translateY(-50%);
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M16.207 9.793a1 1 0 0 0-1.414 0L12 12.586 9.207 9.793a1 1 0 0 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
            transition: transform .2s; } }

    @include m {
        width: 100%; } }

.menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 12px;

    @include t {
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        right: 0;
        box-shadow: 0px 16px 64px -32px rgba(31, 47, 70, 0.15);
        border-radius: 12px;
        background: $n8;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        @include dark {
            background: $n0; } }

    @include m {
        padding-top: 0;
        border: $blueGray700 solid 1px; } }

.item {
    display: flex;
    align-items: center;
    height: 48px;
    @include button-2;
    color: $n4;
    transition: color .2s;
    svg {
        margin: 0 14px 0 22px;
        fill: $n4;
        transition: fill .2s; }
    &:hover,
    &.active {
        color: $n2;
        @include t {
            background: $n6; }
        svg {
            fill: $n2; }
        @include dark {
            color: $n8;
            @include t {
                background: $blueGray700; }
            svg {
                fill: $n8; } } }
    &:not(:last-child).separator {
        position: relative;
        margin-bottom: 41px;
        @include t {
            margin-bottom: 0; }
        &:after {
            content: "";
            position: absolute;
            top: calc(100% + 20px);
            left: 0;
            right: 0;
            height: 1px;
            background: $n6;
            @include dark {
                background: $n2; }
            @include t {
                display: none; } } }
    &.separator ~ & {
        @include t {
            display: none; } } }

.bg {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    margin: 0 18px 0 26px;
    border-radius: 4px; }

.btns {
    padding: 12px;
    @include t {
        display: flex;
        padding: 0;
        order: -1; }

    @include m {
        display: none; } }

.withdrawBtns {
    margin-bottom: 40px;
    margin-top: -8px;
    padding: 16px;
    @include t {
        display: flex;
        margin: 0 -6px;
        padding: 0;
        order: -1; } }

.button {
    width: 100%;
    margin: 0 0 12px 0;

    @include t {
        flex: 1;
        margin: 0 12px 12px 0; }

    &:last-of-type {
        margin: 0; } }
